<template>
  <div class="tool-entrance-btn" :style="{ bottom: bottom + 'px' }">
    <div class="entrance-btn" @click="toggleOpen" @mousedown="mousedown">
      <div class="entrance-btn-inner">
        <a-tooltip placement="left">
          <template #title>
            <span>点击{{ isOpen ? '收起' : '展开' }}</span>
          </template>
          <i class="iconfont" v-html="isOpen ? '&#xe603;' : '&#xe60a;'"></i>
        </a-tooltip>
      </div>
    </div>
    <div class="open-list" v-show="isOpen">
      <div class="item">
        <a-popover
          trigger="hover"
          placement="left"
          :getPopupContainer="(node: HTMLElement) => node.parentNode"
        >
          <template #content>
            <div class="img-container">
              <p>打开微信扫码添加火山湖专属客服</p>
              <div
                :style="{
                  backgroundImage: `url(${preview(customerCard)})`,
                }"
              ></div>
            </div>
          </template>
          <i class="iconfont" v-html="'&#xe889;'"></i>
        </a-popover>
      </div>
      <div class="item" @click="openCart" v-if="userInfo?.organizationType !== 'platform'">
        <a-tooltip placement="left">
          <template #title>
            <span>点击打开愿望清单</span>
          </template>
          <i class="iconfont" v-html="'&#xe601;'"></i>
        </a-tooltip>
      </div>
      <div class="item" v-if="servicePhone">
        <a-tooltip placement="left">
          <template #title>
            <span>{{ servicePhone }}</span>
          </template>
          <i class="iconfont" v-html="'&#xe969;'"></i
        ></a-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getDomain } from '@/utils'
import http, { preview } from '@/utils/http'
import { ref, nextTick, toRaw, computed } from 'vue'
import { getUserInfo, data } from '../layout/header/use-login-info'

const userInfo = computed(() => data.value)

const storeBottom = localStorage.getItem('TOOL-ENTRANCE-BTN-POSITION') || 0
const customerCard = ref('')
const servicePhone = ref('')
const linkedOrganizationId = ref()
const bottom = ref(storeBottom || 100)
getUserInfo()
http({
  url: '/pms/api/sales-setting/query-service-info',
}).then((re: any) => {
  customerCard.value = re.card
  servicePhone.value = re.servicePhone
  linkedOrganizationId.value = re.linkedOrganizationId
})

let moving = false
const storeIsOpen = localStorage.getItem('TOOL-ENTRANCE-BTN-OPEN')
  ? localStorage.getItem('TOOL-ENTRANCE-BTN-OPEN') === '1'
  : true
const isOpen = ref(storeIsOpen)
const toggleOpen = () => {
  if (moving) return
  isOpen.value = !isOpen.value
  localStorage.setItem('TOOL-ENTRANCE-BTN-OPEN', isOpen.value ? '1' : '0')
}
const openCart = () => {
  window.open(`${getDomain()}/#/purchase/purchase-info/shop-cart`)
}
const mousedown = () => {
  const viewHeight = document.body.clientHeight
  const globalMousemove = (e: MouseEvent) => {
    moving = true
    let re = viewHeight - e.y - 15
    if (re < 100) re = 100
    if (re > 300) re = 300
    bottom.value = re
  }
  const globalMouseup = () => {
    document.removeEventListener('mousemove', globalMousemove)
    document.removeEventListener('mouseup', globalMouseup)
    localStorage.setItem('TOOL-ENTRANCE-BTN-POSITION', toRaw(bottom.value).toString())
    setTimeout(() => {
      moving = false
    }, 200)
  }
  document.addEventListener('mousemove', globalMousemove)
  document.addEventListener('mouseup', globalMouseup)
}
</script>

<style scoped lang="less">
@import '~@skycore/skycore-ui/lib/index.less';
.tool-entrance-btn {
  position: fixed;
  right: 14px;
  bottom: 100px;
  z-index: 99999;

  @hoverStyle: {
    &:hover {
      color: #ffffff;
      background-color: @primary-color;
    }
  };
  i {
    user-select: none;
  }
  .entrance-btn {
    cursor: pointer;
    > .entrance-btn-inner {
      display: flex;
      justify-content: center;
      padding: 10px 0;
      width: 32px;
      height: 32px;
      font-size: 0;
      border-radius: 28px;
      border-radius: 100%;
      text-align: center;
      background-color: #ffffff;
      box-shadow: 0 46px 49px -2px rgb(19 41 75 / 15%), 0 14px 15px -2px rgb(19 41 75 / 14%),
        0 6px 6px -2px rgb(19 41 75 / 14%), 0 2px 2px -2px rgb(19 41 75 / 13%);
      flex-direction: column;
      cursor: pointer;
      box-sizing: border-box;
      @hoverStyle();
    }
  }
  .open-list {
    position: absolute;
    top: -20px;
    left: -5px;
    transform: translateY(-100%);
    .item {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      width: 40px;
      height: 40px;
      font-size: 0;
      border-radius: 28px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0 26px 29px -2px rgb(19 41 75 / 15%), 0 4px 5px -2px rgb(19 41 75 / 14%),
        0 6px 6px -2px rgb(19 41 75 / 14%), 0 2px 2px -2px rgb(19 41 75 / 13%);
      flex-direction: column;
      cursor: pointer;
      box-sizing: border-box;
      @hoverStyle();
    }
  }
}
.img-container {
  position: relative;
  width: 234px;
  height: 494px;
  p {
    position: absolute;
    top: 30px;
    width: 100%;
    text-align: center;
    color: #000000;
  }
  > div {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
