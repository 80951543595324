<template>
  <div class="footer bg-gray-800">
    <div class="block-container relative w-full text-gray-400 text-sm">
      <div
        class="container max-w-7xl relative z-10 w-full px-4 mx-auto py-6 flex flex-wrap justify-between"
      >
        <div class="mb-8 lg:mb-0 w-full lg:w-auto hidden md:block">
          <a
            class="block mb-4 text-white text-xl hover:text-white"
            href="https://www.skycoresaas.com/about"
            >关于我们</a
          >
          <div class="leading-loose">
            <a class="block hover:text-white" href="/#/about">公司简介</a
            ><a class="block hover:text-white" href="/#/culture">公司文化</a
            ><a class="block hover:text-white" href="/#/word">公司业务</a
            ><a class="block hover:text-white" href="/#/news">新闻资讯</a>
          </div>
        </div>
        <div class="mb-8 lg:mb-0 w-full lg:w-auto hidden md:block">
          <a class="block mb-4 text-white text-xl hover:text-white" href="/#/design">在线工具</a>
          <div class="leading-loose">
            <a class="block hover:text-white" href="/#/design">在线设计</a>
            <a class="block hover:text-white" href="/#/work-system">工单系统</a>
          </div>
        </div>
        <div class="mb-8 lg:mb-0 w-full lg:w-auto hidden md:block">
          <a class="block mb-4 text-white text-xl hover:text-white" href="/#/classroom"
            >火山云课堂</a
          >
          <div class="leading-loose">
            <a class="block hover:text-white" href="/#/classroom">火山云课堂</a>
          </div>
        </div>
        <div class="mb-8 lg:mb-0 w-full lg:w-auto hidden md:block">
          <div class="mb-4 text-white text-xl">联系我们</div>
          <div class="leading-loose">
            <a class="block hover:text-white" href="/#/contact">合作联系</a
            ><a class="block hover:text-white" href="/#/join">加入我们</a>
          </div>
        </div>
        <div class="mb-8 lg:mb-0 w-full lg:w-auto text-center md:text-left text-xs md:text-sm">
          <div class="text-left footer-logo">
            <img src="./logo.png" />
          </div>
          <div class="mb-4 text-white text-xl text-left">联系方式</div>
          <div class="leading-loose">
            电话： <span class="p">{{ tel.value }}</span>
            <br />
            邮箱： <span>{{ email.value }}</span
            ><br />

            <div v-for="(item, index) in address" :key="index">
              <span v-if="index == 0">地址：</span>
              <span v-if="index > 0" style="width: 3em; display: inline-block"></span> •
              {{ item.value }}
            </div>

            <br /><br />
            <p class="mt-2 text-xs title-">官方微信公众号</p>
            <img src="/static/media/qrcode.png" alt="qrcode" class="w-24 img-" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="block-container relative w-full -mt-10 md:-mt-0 pb-10 text-center md:text-left">
      <div class="container max-w-7xl relative z-10 w-full px-4 mx-auto">
        <a
          href="https://www.aucotec.cn/"
          target="_blank"
          rel="noreferrer"
          class="text-lg md:text-2xl text-gray-300 hover:text-white"
          >EB官网（中国）</a
        >
      </div>
    </div> -->
    <div
      class="block-container relative w-full text-gray-400 text-sm border-t border-gray-600 flex"
    >
      <!-- <a
		   href="https://www.aucotec.cn/"
		   target="_blank"
		   rel="noreferrer"
		   class="text-lg md:text-2xl  text-gray-300 hover:text-white"
		   >EB官网（中国）</a
		 > -->
      <div class="container max-w-7xl relative z-10 w-full px-4 mx-auto">
        <p class="py-6 text-xs text-center leading-relaxed">
          <span>版权所有 © 上海火山湖信息技术有限公司 All rights reserved.</span
          ><br class="block lg:hidden" />&emsp;&emsp;<span>备案号：</span
          ><a
            target="_blank"
            rel="noreferrer"
            class="hover:text-gray-200 transition-all duration-200"
            href="https://beian.miit.gov.cn/"
            >沪 ICP 备 2021002564 号</a
          ><br class="block lg:hidden" />&emsp;&emsp;<img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAElklEQVQ4jY3UW2xTBRzH8e85PT1tz3rdunZ1xQksMO5DQI1OQFRAMoZZCIj6IFFD1Chi4EHBGIMx+jKCQaJBHoTwoIGEhBHkIkYui2AMKBDYxhiXdoytl7WnPaft6Tn1yQUCJP7ff5+HX/L/CZVKhYdfvE67dq7tt0N/vl3Uy/L8hTN2VE+fvh+aYg9LCA8ESyfm9x468s7pX3raKnqv81ZRwRbw0xB0oieUcsvz4zonLW3fiTyj83+ABefxrWv0jV9289qSCjOrTMbmR3AEFDIvtnAuodDxYReffdzAwvU7HOAu3Z0W78WS0eK5zYe37smw6q1xvBHN4vv+Esluk2SfSab1O5aZF1i76xV27DOodP+wD645HwiW6W+62bmpb27rhbn+xjDvBS/QvyeLOMaPqZagaCHU+ul6/zQrlHOEn5nJC60HW4dP7exP9nRMuA+UCofdB7o98tmB2bw0WyV3Pot4M4Pgd+BImRRO38E+tgZnTifx4xnaF5U4fnUyu0+adW4hr9wDZi4fi1p//LrFElwgaXjVAbKDYDcNSBUQxivIs/yIt/PIWCT6deqriyAIpHMijlppS8VIPjYK3rhytL0Uj7cE3DKUHVQiEYItdeSooD5dg7kqivvzZpS10xFrFUKzguS1ElQqREIKxM7PT93qenMUVNyBKVnVYFxtAZw1HDmjIC3wklzagBlXEf7Jox28g9E/gryhhep1M9i7Kw1U0TxNIt83RCHWFx0FrdRAazYtMTWqEn1CZvvuKn7/KkbznAg+xYN2KY01VKRwIY0nPsLFzT18s8tBuNnPtPoMiXgRI3WlZRQUpbxfzYp4h3tYscikbLgxOgep+ekvxHo7pVobVkgiuCBC5Pg1Ejsuki/ZaG+z40n2omYlJI8oA0gALuXRY+LE621muJ7VT6p01NeyPT4J49YlapK9iD4X+qU0amOIeCDCz6ILvH5WPzVE2RPFP0VACYzpHAVBDFWyFQb6VKZO6ueL9bPYtGEuneo0yBq0aUmq/SJ7D3rIoQABPnrXyZzGXgZ6gYKGVVabR0HJX5pd9oQxBg1iQ5f5oMVB+7Eqjh/SuCJOZ/Hkq0RcCcoH6imldcY0KXyy+Dp3uq6jGU6cQRnJLzeN/nIxd/bV5Jmv9+iDJYoJA2I3GP9yM46wE8wkZlIiN6TjG1sNDTUQS9C/vxvdE8Q9zo2rTsHXuGqeHFhyYnQcyvm/X09d7PjWLOjexMlBpKoKdfMmYaZ0DC2PqDhxVlchyHaSXX2UTQnf7PEYBVK+iXNXukPLj93VIWjDeqww6NKcYcsbXtCImcljqllQZGyyH9kFpZEc+VgaZ0MNzmiY4ohGIa5arjG5+8fBxqmNocc9dfZAhGLGwjIlLNNGYViFikUpmSHXO4xgs4MgULidwO4SCT8bCcqKuuY+0FEzZ5mq+bZpsZgm20bwhsrYBQ1ZMLAVR6joOp6mIN5GL6KcQ/LJCIG6m2Vp5qeSMmHlf859A3tx27qgnhleXDRuPffI5NrJlmq5nX6xWnK50LPcdNX6NSk09Xyqb+ioXk4fmbG8w7o7/y9OUgii97GFIwAAAABJRU5ErkJggg=="
            alt="badge"
            class="w-4 h-4 inline"
          />&nbsp;<span>沪公网安备：</span
          ><a
            target="_blank"
            rel="noreferrer"
            class="hover:text-gray-200 transition-all duration-200"
            href="https://beian.miit.gov.cn/"
            >31010102006947 号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { getConfigContact } from './footer.api'

const tel = ref({
  key: '',
  title: '电话',
  value: '400 158 6678',
})
const email = ref({
  key: '',
  title: '邮箱',
  value: 'kycore@skycoresaas.com',
})

const address = ref([
  {
    key: '',
    title: '地址',
    value: '',
  },
])

getConfigContact().then((res: any) => {
  tel.value = res.tel
  email.value = res.email
  address.value = res.address
})
</script>
<style>
.footer-logo {
  display: none;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  margin-bottom: 1.5rem;
}
</style>
<style lang="less" scoped>
@media (max-width: 768px) {
  .footer-logo {
    display: block;
    img {
      width: 100px;
    }
  }
  .leading-loose {
    .p {
      font-weight: bold;
      font-size: 16px;
      color: #66c5f8;
    }
    .title- {
      text-align: center;
    }
    .img- {
      margin: 0 auto;
      display: block;
    }
  }
}
</style>
