<template>
  <Affix :offset-top="0" class="header-affix">
    <div
      class="z-20 right-0 left-0 py-4 md:py-6 lg:py-8 transition-all duration-300"
      :class="{
        'bg-white-900 shadow-lg': isScroll,
        fixed: isSeriesPage,
        sticky: !isSeriesPage,
        'bg-gray-800 shadow': !isSeriesPage,
      }"
    >
      <div class="block-container relative w-full">
        <div class="container relative z-10 px-4 mx-auto flex justify-between items-center">
          <a href="/"><img :src="isScroll ? Logo : AntiLogo" class="h-8 md:h-12" alt="logo" /></a>
          <div class="hidden md:flex items-center">
            <span v-for="nav in navList" :key="nav.url">
              <a-popover
                v-if="nav.children?.length"
                placement="bottom"
                overlayClassName="header-popover"
                :getPopupContainer="(ele: HTMLElement) => ele.parentElement"
              >
                <!-- active: nav.url === `/oms/#/series`, -->
                <a
                  class="header-action"
                  :class="{
                    scroll: isScroll,
                    active: highLight(nav.url),
                  }"
                  :href="nav.url"
                  aria-current="page"
                >
                  {{ nav.title }}
                </a>
                <template #content>
                  <a
                    v-for="child in nav.children"
                    :key="child.url"
                    class="block px-4 py-2 text-center text-sm lg:text-base"
                    :href="child.url"
                    >{{ child.title }}</a
                  >
                </template>
              </a-popover>
              <a
                v-else
                class="header-action"
                :class="{
                  scroll: isScroll,
                  active: highLight(nav.url),
                }"
                :href="nav.url"
                aria-current="page"
              >
                {{ nav.title }}
              </a>
            </span>
            <span>
              <a href="/#/search" class="header-action">
                <img :src="fangdajing" />
              </a>
            </span>
            <!-- <span
            ><a
              class="header-action"
              :class="{ scroll: isScroll }"
              :href="getOfficeDomain() + '/about'"
            >
              关于我们
            </a></span
          >
          <span>
            <a
              class="header-action"
              :class="{ scroll: isScroll }"
              :href="getOfficeDomain() + '/service'"
            >
              服务概览
            </a>
          </span>
          <span>
            <a
              class="header-action"
              :class="{ scroll: isScroll }"
              :href="getOfficeDomain() + '/cases'"
            >
              客户案例
            </a>
          </span>
          <span>
            <a
              class="header-action"
              :class="{ scroll: isScroll }"
              :href="getOfficeDomain() + '/training'"
            >
              培训教程
            </a>
          </span>
          <span><a class="header-action active" :class="{ scroll: isScroll }"> 产品选型 </a></span>
          <span>
            <a
              class="header-action"
              :class="{ scroll: isScroll }"
              :href="getOfficeDomain() + '/contact'"
            >
              联系我们
            </a>
          </span> -->
            <span v-if="!userInfo" @click="toLoginPage">
              <a class="header-action header-action-login" :class="{ scroll: isScroll }">登录</a>
            </span>
            <a-popover
              v-else
              placement="bottom"
              class="login-a-popover"
              :getPopupContainer="(ele: HTMLElement) => ele.parentElement"
            >
              <span>
                <a class="user" :class="{ scroll: isScroll }">
                  <Avatar size="26" :name="userInfo.name" :uuid="userInfo.avatar" />
                  <span class="text-base font-semibold ml-2" :class="{ 'text-black': isScroll }">{{
                    userInfo.name
                  }}</span>
                  <i class="arrow-down ml-2"></i>
                </a>
              </span>
              <template #content>
                <div class="user-info px-4 py-2">
                  <div>
                    <Button
                      @click="toHomePage"
                      type="text"
                      block
                      style="border: none; color: #333333; background-color: transparent"
                      >进入协同智造平台</Button
                    >
                  </div>
                  <div class="logout" @click="handleLogout">
                    <!-- <LogoutOutlined /> -->
                    退出
                  </div>
                </div>
              </template>
            </a-popover>
          </div>

          <div class="block md:hidden webnav" :class="{ on: webNavShow }">
            <ul>
              <li v-for="nav in navList" :key="nav.url">
                <div class="ts">{{ nav.title }}</div>
                <div v-for="child in nav.children" class="sec" :key="child.title">
                  <a :href="child.url">
                    <span>{{ child.title }}</span>
                    <i class="arrow-right"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div class="block md:hidden" style="z-index: 1">
            <div class="p-2 text-2xl cursor-pointer text-white" style="line-height: 0">
              <span
                role="img"
                aria-label="menu"
                class="anticon anticon-menu"
                @click="webNavShow = !webNavShow"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="menu"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:hidden webmask" :class="{ on: webNavShow }" @click="webNavShow = false"></div>
  </Affix>
</template>

<script setup lang="ts">
import { Button, Affix } from 'ant-design-vue'
import { getDomain } from '@/utils'
import { computed, onMounted, ref, watch } from 'vue'
import { preview } from '@/utils/http'
import { useRoute } from 'vue-router'
import { Avatar } from '@skycore/skycore-ui'
import { logout } from './header.api'
import { useScroll } from '../utils'
import fangdajing from '../../../public/static/media/s.png'
import Logo from '../../../public/static/media/logo.png'
import AntiLogo from '../../../public/static/media/logo-anti.png'
import { getUserInfo, data, clearUserInfo } from './use-login-info'

const userInfo = computed(() => data.value)

const scrollTop = useScroll()
const route = useRoute()
const webNavShow = ref(false)

const isSeriesPage = computed(
  () =>
    route.path === '/series' ||
    route.path === '/home' ||
    route.path === '/about' ||
    route.path === '/classroom' ||
    route.path === '/culture' ||
    route.path === '/word' ||
    route.path === '/news' ||
    route.path === '/contact' ||
    route.path === '/join' ||
    route.path === '/design' ||
    route.path === '/work-system' ||
    route.path === '/bom' ||
    route.path === '/internet' ||
    route.path === '/case',
)

const isScroll = computed(() => isSeriesPage.value && scrollTop.value > 120)

const highLight = (url: string) => {
  return (
    (url === '/#/series' &&
      (route.path === '/series-model-select' ||
        route.path === '/series-cabinet-list' ||
        route.path === '/series-cabinet-detail' ||
        route.path === '/series')) ||
    (url === '/#/about' &&
      (route.path === '/about' ||
        route.path === '/word' ||
        route.path === '/news' ||
        route.path === '/culture')) ||
    (url === '/#/design' && (route.path === '/design' || route.path === '/work-system')) ||
    (url === '/#/contact' && (route.path === '/contact' || route.path === '/join')) ||
    (url === '/#/classroom' && route.path === '/classroom')
  )
}

const handleLogout = () => {
  logout().then(() => {
    clearUserInfo()
  })
}

const toLoginPage = () => {
  const params = `?redirectUrl=${encodeURIComponent(window.location.href)}`

  window.location.replace(`${getDomain()}/#/login${params}`)
}

const toHomePage = () => {
  window.location.href =
    userInfo.value?.existLastOrganization === true
      ? `${getDomain()}/#/home/index`
      : `${getDomain()}/#/organization`
}

onMounted(() => {
  getUserInfo()
})

const navList = [
  // { title: '首页', url: '/' },
  {
    title: '关于我们',
    url: '/#/about',
    children: [
      { title: '公司简介', url: '/#/about' },
      { title: '公司文化', url: '/#/culture' },
      { title: '公司业务', url: '/#/word' },
      { title: '新闻资讯', url: '/#/news' },
      // { title: '我们的团队', url: '/about/team/' },
      // { title: '资讯新闻', url: '/about/news/' },
    ],
  },
  {
    title: '在线工具',
    url: '/#/design',
    children: [
      { title: '在线设计', url: '/#/design' },
      { title: '工单系统', url: '/#/work-system' },
      { title: '一键BOM', url: '/#/bom' },
      { title: '生产实况直播', url: '/#/live' },
    ],
  },

  {
    title: '火山云课堂',
    url: '#/classroom',
    children: [{ title: '火山云课堂', url: '/#/classroom' }],
  },

  // { title: '培训教程', url: '/training/' },
  {
    title: '火山湖商城',
    url: '/#/series',
    children: [
      { title: '机柜系列', url: '/#/series' },
      { title: '机柜配置', url: '/#/series-model-select' },
      { title: '配件选型', url: '/#/accessory-model-select' },
    ],
  },
  {
    title: '联系我们',
    url: '/#/contact',
    children: [
      { title: '合作联系', url: '#/contact' },
      { title: '加入我们', url: '#/join' },
    ],
  },
]

watch(
  () => route.path,

  (newValue, oldValue) => {
    webNavShow.value = false
  },

  { immediate: true },
)
</script>

<style lang="less" scoped>
.arrow-right {
  display: inline-block;
  margin: 10px 0;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #333;
  background-color: transparent;
  vertical-align: top;
  transform: rotate(45deg);
}
.webmask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  &.on {
    display: block;
  }
}
.webnav {
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  background-color: #fff;
  z-index: 0;
  height: 100vh;
  padding: 10px;
  top: 0;
  transition: all 0.3s;
  transform: translateX(100%);
  &.on {
    transform: translateX(0%);
  }
  .ts {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
  .sec {
    a {
      font-size: 12px;
      padding: 6px 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.arrow-down {
  position: relative;
  margin-top: 4px;
  width: 0;
  height: 0;
  border: 5px solid;
  border-color: #ffffff transparent transparent;
}
.round50 {
  border-radius: 50%;
}
.header-action-login {
  display: inline-block;
  min-width: 6em;
  text-align: center;
  // background-color: #66c5f8;
}
.header-action-login.scroll {
  background-color: transparent;
}
.header-action {
  margin-left: 1.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 9999px;
  white-space: nowrap;
  color: white;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.75rem;
  box-sizing: border-box;
  &:hover {
    background-color: hsl(0deg 0% 100% / 20%);
  }
  &.active {
    border-color: white;
    &.scroll {
      border-color: rgb(103 202 255);
    }
  }
  &.scroll {
    color: black;
    &:hover {
      color: rgb(103 202 255);
    }
    &.active {
      color: rgb(103 202 255);
    }
  }
}
.user {
  display: inline-flex;
  align-items: center;
  margin-left: 1rem;
  padding: 6px 16px;
  font-size: 1rem;
  border-radius: 4px;
  color: #ffffff;
}
.user-info {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: white;
  flex-direction: column;
  .user-name {
    margin: 8px 0;
    color: rgb(0 0 0 / 90%);
  }
  .logout {
    padding-top: 8px;
    border-top: 1px solid rgb(0 0 0 / 10%);
    text-align: center;
    color: #333333;
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: 0.9;
    }
  }
}
.header-affix {
  width: 100% !important;
  :deep(.ant-btn-primary) {
    box-shadow: 0 0 0 rgb(0 0 0 / 5%);
  }
  :deep(.ant-popover-inner-content) {
    padding: 0;
  }
  :deep(.ant-affix) {
    z-index: 99999;
  }
}
</style>
