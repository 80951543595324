<template>
  <div class="main-box">
    <main class="main">
      <div class="main-inner">
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup></script>
