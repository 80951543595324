import type { Pagination } from '@/types'
import type { IKeywords } from '@skycore/skycore-ui/lib/form/types'
import { omit, pick } from 'lodash-es'

export const copy = (str: string) => {
  const input = document.createElement('textarea')
  document.body.appendChild(input)
  input.value = str
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
}

export const getQueryParams = (keywords: IKeywords, pagination?: Pagination) => {
  const queryParams = {
    ...omit(keywords, 'createdTime', 'updatedTime', '_searchOptions'),
    ...pick(pagination || {}, 'pageIndex', 'pageSize'),
  } as IKeywords & {
    createdStartTime: string | undefined
    createdEndTime: string | undefined
    updatedStartTime: string | undefined
    updatedEndTime: string | undefined
    pageIndex: number
    pageSize: number | undefined
  }
  return queryParams
}

const { hostname } = window.location
type StringPlainMap = { [key: string]: string }
const OfficeDomainMap: StringPlainMap = {
  localhost: 'localhost:5000',
  'saas.dev.skycoresaas.com': 'dev.skycoresaas.com',
  'saas.qa.skycoresaas.com': 'qa.skycoresaas.com',
  'saas.uat.skycoresaas.com': 'uat.skycoresaas.com',
  'saas.www.skycoresaas.com': 'www.skycoresaas.com',
}

const CimpDomainMap: StringPlainMap = {
  localhost: 'localhost:8081',
  'dev.skycoresaas.com': 'saas.dev.skycoresaas.com',
  'qa.skycoresaas.com': 'saas.qa.skycoresaas.com',
  'uat.skycoresaas.com': 'saas.uat.skycoresaas.com',
  'www.skycoresaas.com': 'saas.www.skycoresaas.com',
}

export const getOfficeDomain = () => `//${OfficeDomainMap[hostname]}`
export const getDomain = () => `//${CimpDomainMap[hostname] || hostname}`
