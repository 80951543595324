const common = {
  action: {
    create: 'Create',
    delete: 'Delete',
  },
  message: {
    error401: 'Please login',
    error403: 'Invalid permission',
  },
}

export default common
