import type { App, Directive } from 'vue'

export default {
  install(app: App) {
    const modules = import.meta.globEager('./**/index.ts')
    Object.keys(modules).forEach(modulePath => {
      const moduleName = modulePath.split('/')[1]
      const directive: Directive = Reflect.get(modules, modulePath).default
      app.directive(moduleName, directive)
    })
  },
}
