import { http } from '@/utils'
import type { UserInfo } from './header'

export const getLoginInfo = () => {
  return http<UserInfo>({
    url: '/ram/api/user/query-login-info',
    redirectDisabled: true,
  })
}

export const logout = () => {
  return http<UserInfo>({
    url: '/ram/api/user/logout',
  })
}
