/**
 * @description 获取 url 查询参数
 * @param key? 参数名，可选，
 * @return string | object | number | undefined , key 不传则返回包含所有参数的对象
 */
export const getQuery = (key?: string) => {
  const result: { [key: string]: any } = {}
  const search = decodeURIComponent(window.location.search)
  const tempArr = search && search.indexOf('?') === 0 ? search.split('?')[1].split('&') : []

  tempArr.forEach(item => {
    if (item) {
      const itemArr = item.split('=')
      // 临时数据类型转换
      const isNumber = itemArr[1].length < 11 && /^\d+$/.test(itemArr[1])
      result[itemArr[0]] = isNumber ? parseInt(itemArr[1], 10) : itemArr[1]
    }
  })

  return key ? result[key] : result
}
