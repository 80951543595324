import { onMounted, onUnmounted, ref } from 'vue'

export const navHrefTransfer = (url: string): string => {
  if (url && process.env.NODE_ENV === 'production' && process.env.CLIENT === 'electron') {
    // electron 环境下需要对url进行转换
    return window.location.href.split('#')[0] + url.slice(1)
  }
  return url
}

export function useScroll() {
  const scrollTop = ref(0)

  const scrollHandler = (e: Event) => {
    const target = (e.target as Document).documentElement
    scrollTop.value = target.scrollTop
  }

  onMounted(() => {
    document.addEventListener('scroll', scrollHandler)
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', scrollHandler)
  })

  return scrollTop
}
