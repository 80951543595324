import { ref } from 'vue'
import type { UserInfo } from './header'
import { getLoginInfo } from './header.api'

let loading = false
export const data = ref<UserInfo>()

export const getUserInfo = () => {
  if (!loading) {
    loading = true
    getLoginInfo().then(res => {
      loading = false
      data.value = res
      localStorage.setItem('userInfo', JSON.stringify(res))
    })
  }
}

export const clearUserInfo = () => {
  data.value = undefined
  localStorage.removeItem('userInfo')
}
