import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutComponent from '@/layout/layout-index.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: LayoutComponent,
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('../pages/home/home-index.vue'),
        },
        {
          path: '/about',
          name: 'about',
          meta: {
            title: '公司简介',
          },
          component: () => import('../pages/about/about-index.vue'),
        },
        {
          path: '/internet',
          name: 'internet',
          component: () => import('../pages/word/internet-index.vue'),
        },
        {
          path: '/case',
          name: 'case',
          component: () => import('../pages/word/case-index.vue'),
        },
        {
          path: '/search',
          name: 'search',
          component: () => import('../pages/search/search-index.vue'),
        },
        {
          path: '/news',
          name: 'news',
          meta: {
            title: '新闻资讯',
          },
          component: () => import('../pages/news/news-index.vue'),
        },
        {
          path: '/news-details',
          name: 'news-details',
          meta: {
            title: '新闻资讯',
          },
          component: () => import('../pages/news/news-details.vue'),
        },
        {
          path: '/culture',
          name: 'culture',
          meta: {
            title: '公司文化',
          },
          component: () => import('../pages/culture/culture-index.vue'),
        },
        {
          path: '/word',
          name: 'word',
          meta: {
            title: '公司业务',
          },
          component: () => import('../pages/word/word-index.vue'),
        },
        {
          path: '/contact',
          name: 'contact',
          meta: {
            title: '合作联系',
          },
          component: () => import('../pages/contact/contact-index.vue'),
        },
        {
          path: '/join',
          name: 'join',
          meta: {
            title: '加入我们',
          },
          component: () => import('../pages/contact/join-index.vue'),
        },
        {
          path: '/bom',
          name: 'bom',
          component: () => import('../pages/tool/bom-index.vue'),
        },
        {
          path: '/live',
          name: 'live',
          component: () => import('../pages/tool/live-index.vue'),
        },
        {
          path: '/design',
          name: 'design',
          meta: {
            title: '在线设计',
          },
          component: () => import('../pages/tool/design-index.vue'),
        },
        {
          path: '/work-system',
          name: 'work-system',
          meta: {
            title: '工单系统',
          },
          component: () => import('../pages/tool/work-system.vue'),
        },
        {
          path: '/skycore',
          name: 'skycore',
          meta: {
            title: '公司业务',
          },
          component: () => import('../pages/word/skycore-index.vue'),
        },
        {
          path: '/skycore-dfm',
          name: 'skycore-dfm',
          meta: {
            title: '公司业务',
          },
          component: () => import('../pages/word/skycore-dfm.vue'),
        },
        {
          path: '/classroom',
          name: 'classroom',
          meta: {
            title: '火山湖云课堂',
          },
          component: () => import('../pages/classroom/classroom-index.vue'),
        },
        {
          path: '/series',
          name: 'series',
          meta: {
            title: '机柜系列',
          },
          component: () => import('../pages/oms/series/series-index.vue'),
        },
        {
          path: '/series-cabinet-list',
          name: 'series-cabinet-list',
          component: () => import('../pages/oms/series/series-cabinet-list-index.vue'),
        },
        {
          path: '/series-cabinet-detail',
          name: 'series-cabinet-detail',
          component: () => import('../pages/oms/series/series-cabinet-detail-index.vue'),
        },
        {
          path: '/series-model-select',
          name: 'series-model-select',
          component: () => import('../pages/oms/series/series-model-select-index.vue'),
        },
        {
          path: '/accessory-model-select',
          name: 'accessory-model-select',
          meta: {
            title: '配件选型',
          },
          component: () => import('../pages/oms/series/accessory-model-select-index.vue'),
        },
        {
          path: '/accessory-detail',
          name: 'accessory-detail',
          component: () => import('../pages/oms/series/accessory-detail-index.vue'),
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: '/',
    },
  ],
})
router.beforeEach((to, form) => {
  if (!to.query.s) {
    document.title = to.meta.title ? (`${to.meta.title} - 火山湖科技` as string) : '火山湖科技'
  }
  return true
})

export default router
